<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">DAMAYAN INCENTIVES MONITORING</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="month_of_id"
            class="mx-2"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
      >
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountCashOutline,
    mdiAccountOutline,
    mdiAccountSearch,
    mdiInformationOutline
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      liquidated_amount: '0.00',
      rf_amount: '0.00',
      coh_amount: '0.00',
      released_amount: '0.00',
      total_amount: '0.00',
      month_of_id: '',
      month_of_items: [],
      data_items: [],
      headers: [
        {text: 'Branch', value: 'branch_code', sortable: false},
        {text: 'Production Incentives', value: 'production_incentives_request', sortable: false},
        {text: 'BM Incentives', value: 'bm_incentives_request', sortable: false},
        {text: 'RM Incentives', value: 'rm_incentives_request.last_name', sortable: false},
        {text: 'TH Incentives To', value: 'th_incentives_request', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months', ['list_of_transaction_month',]),
      ...mapActions('request_data', ['damayan_incentives_monitoring_details',]),
      initialize_data() {
        this.list_of_transaction_month()
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        var index = this.month_of_items
          .map(function (x) {
            return x.id
          })
          .indexOf(this.month_of_id)
        if (index != -1) {
          const data = new FormData()
          data.append('month_of', this.month_of_items[index].month_of);
          this.damayan_incentives_monitoring_details(data)
            .then(response => {
              this.data_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
    },
  }
</script>
